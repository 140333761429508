import { makeStyles } from '@material-ui/core';
import { COLORS } from './plTheme';

export const useGlobalStyles = makeStyles({
  body2Light: {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '14px',
    letterSpacing: 'normal',
  },
  h5Medium: {
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
  },
  body2Medium: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  body1WidgetTitle: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: COLORS.WIDGET_TITLE,
    textTransform: 'uppercase',
    letterSpacing: '0em',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  body1Light: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '14px',
    letterSpacing: 'normal',
  },
  h2Light: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: 'normal',
  },
  h4Light: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: 'normal',
  },
  roboto500px48px32px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: '32px',
    letterSpacing: 'normal',
  },
  roboto400px18px32px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: 'normal',
  },
  roboto700px20px32px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: 'normal',
  },
  roboto400px18px22px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: 'normal',
  },
  roboto400px14px21px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: 'normal',
  },
  roboto500px20px32px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: 'normal',
  },
  roboto400px9px16px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  roboto400px12px16px: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  h4Bold: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: 'normal',
  },
  body1Light24: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
  },
  h5Bold: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
});
